<template>
  <div class="container">
    <h4 class="mt-4 text-center">Публикация курсов в Moodle</h4>

    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>

    <div v-else-if="curriculumDisciplinesData?.discipline?.name">

      <h5 class="mt-3 text-center">
        <b>{{curriculumDisciplinesData?.discipline?.code}}</b>
        {{curriculumDisciplinesData?.discipline?.name}}
        ({{curriculumDisciplinesData?.discipline?.language?.native_name}})
        семестр {{semester}}
      </h5>

      <div class="mt-4">
        <router-link :to="`/binding-to-curriculum?discipline_id=${discipline_id}&semester=${semester}`">
          В страницу приязка преподавателя к группам
        </router-link>
      </div>

      <div v-if="courses.length">

        <div class="row mt-4">
          <div class="col-md-6">
            <label for="main_course"><b>Основной курс</b></label>
            <select class="form-select mt-2" id="main_course" v-model="main_course">
              <option v-for="(item, index) in courses"
                      :value="item.id"
                      :key="index">
                {{item?.group_name}} (Л: {{item?.lecture?.ppsData?.lastname}}, С: {{item?.seminar?.ppsData?.lastname}})
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <div>
              <div>
                <b>Курсы, которые надо объединить с основным курсом</b>
              </div>
              <div class="form-check mt-2" v-for="(item, index) in groupCourses" :key="index">
                <input class="form-check-input" type="checkbox"
                       :id="'otherCourse'+index"
                       :value="item.id"
                       v-model="other_courses">
                <label class="form-check-label" :for="'otherCourse'+index">
                  {{item?.group_name}} (Л: {{item?.lecture?.ppsData?.lastname}}, С:
                  {{item?.seminar?.ppsData?.lastname}})
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="main_course">
          <div class="fw-bold">Для данных курсов будет создан один курс в Moodle:</div>
          <ul class="mb-1">
            <li v-for="(item, courseIndex) in checkedCourses" :key="courseIndex">
              {{item?.group_name}} (Л: {{item?.lecture?.ppsData?.lastname}}, С: {{item?.seminar?.ppsData?.lastname}})
            </li>
          </ul>
        </div>


        <div class="text-center my-4">
          <button class="btn btn-primary" :disabled="publishToMdlBtnDisabled||!main_course"
                  @click="createMdlCourse">
          <span v-if="publishToMdlBtnDisabled" class="spinner-grow spinner-grow-sm" role="status"
                aria-hidden="true"></span>
            {{ publishToMdlBtnDisabled ? 'Публикация в Moodle' : 'Опубликовать в Moodle' }}
          </button>
        </div>

      </div>
      <div v-else class="my-4 text-center">Нет курсов для публикации в Moodle</div>

    </div>

    <div v-else class="my-4 text-center">Здесь пусто</div>

  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

  export default {
    name: 'PublishToMoodle',
    data() {
      return {
        discipline_id: this.$route.query.discipline_id || 0,
        semester: this.$route.query.semester || 0,
        courses: [],
        loading: true,
        main_course: 0,
        other_courses: [],
        publishToMdlBtnDisabled: false
      }
    },
    computed: {
      ...mapState('educationCurriculum', ['curriculumDisciplinesData']),
      groupCourses() {
        if (this.main_course) {
          const course = this.courses.find(c => c.id == this.main_course)
          return this.courses.filter(c => c.lecture_pps_id == course.lecture_pps_id && c.seminar_pps_id == course.seminar_pps_id).filter(c => c.id != course.id)
        }
        return []
      },
      checkedCourses() {
        return [this.main_course, ...this.other_courses].map(i => this.courses.find(c => c.id == i))
      },
    },
    watch: {
      main_course() {
        this.other_courses = []
      }
    },
    methods: {
      ...mapActions('educationCurriculum', ['GET_TEACHERS_GROUPS_FLOWS_BY_DISCIPLINE', 'CREATE_MDL_COURSE']),
      async createMdlCourse() {
        this.publishToMdlBtnDisabled = true
        const coursesIds = {ids: [...new Set([this.main_course, ...this.other_courses])]}

        console.log(coursesIds, 'coursesIds')

        if (coursesIds.ids.length) {
          const res = await this.CREATE_MDL_COURSE(coursesIds)
          this.clearCourses()
          await this.getCourses()

          if (res) {
            this.$message({text: 'Курс успешно создан в Moodle'})
          } else {
            this.$error({text: 'Произошла ошибка'})
          }
        }

        this.publishToMdlBtnDisabled = false
      },
      clearCourses() {
        this.main_course = 0
        this.other_courses = []
      },
      async getCourses() {
        if (this.discipline_id && this.semester) {
          await this.GET_TEACHERS_GROUPS_FLOWS_BY_DISCIPLINE({
            discipline_id: this.discipline_id,
            semester: this.semester
          })
          const teachers = this.curriculumDisciplinesData.teachers

          this.courses = this.curriculumDisciplinesData.groups
            .filter(i => !!i.id && !i.mdl_course_id && !!i.lecture_pps_id && !!i.seminar_pps_id)
            .map(c => ({
              ...c,
              lecture: teachers.find(i => i.pps_id == c.lecture_pps_id)?.pps,
              seminar: teachers.find(i => i.pps_id == c.seminar_pps_id)?.pps
            }))

          console.log(this.courses, 'courses')
        }
      }
    },
    async mounted() {
      await this.getCourses()
      this.loading = false
    }
  }
</script>


<style scoped>

</style>